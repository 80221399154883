import type { Product } from '~/types/product'

export function useUtils() {
  function debounce<T extends (...args: (string | number)[]) => Promise<void>>(fn: T, ms = 300) {
    let timeoutId: ReturnType<typeof setTimeout>

    return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => fn.apply(this, args), ms)
    }
  }

  function getAllCategories(product: Product) {
    const categories: string[] = []

    for (const category in product?.categories) {
      const titles = product.categories[category]?.map((item) => item.title) ?? []
      categories.push(...titles)
    }

    const categoryObject: { [key: string]: string } = {}
    categories.forEach((title, index) => {
      if (index > 0) {
        categoryObject[`item_category${index + 1}`] = title
      } else {
        categoryObject['item_category'] = title
      }
    })

    return categoryObject
  }

  return {
    debounce,
    getAllCategories,
  }
}
